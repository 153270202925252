import axios from "axios";
import {
    BASE_URL_API,
    LS_TOKEN_KEY,
    URL_API_USER_ME,
    URL_API_USER_LOGIN,
    URL_RESERVATION,
    URL_CASH_REPORT_DAY,
    URL_TRANSACTION,
    URL_FEE_LOGS, URL_ANALYTICS, URL_ESTIMATE_RESERVATION

} from "../common/constants";
import {Utils} from "../common/utils"
import {RESERVATION_STATES} from "../common/keys";

const client = axios.create({
  baseURL: BASE_URL_API,
  responseType: "json"
});

function generateAuthorizationHeader() {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(LS_TOKEN_KEY)
    }
  };
}

export const doLoginUser = (username, password) => {
  const params = {
    username,
    password
  };
  return client
    .post(URL_API_USER_LOGIN, params)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getUserInfo = () => {
  return client
    .get(URL_API_USER_ME, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getReservations = (page, filter=null) => {
  let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_RESERVATION, page);
  if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      urlReservation += `&${key}=${value}`
    }
  }

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getCashReportDay = (payment_mode=null) => {
  let urlReservation = URL_CASH_REPORT_DAY + "?payment_mode=" + payment_mode;

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res.data);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getReservationDetail = (id) => {
    let urlReservation = URL_RESERVATION + id + "/";

    return client
        .get(urlReservation, generateAuthorizationHeader())
        .then(res => {
            console.log(res.data);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
}

export const getTransactions = (page, filter=null) => {
    let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_TRANSACTION, page);
    if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      if(value){
        urlReservation += `&${key}=${value}`
      }
    }
    
  }

  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getFeeLogs = (filter=null) => {
  let urlReservation = Utils.composeUrlWithLimitAndOffset(URL_FEE_LOGS);
  if (filter != null) {
    for (const [key, value] of Object.entries(filter)) {
      if(value){
        urlReservation += `&${key}=${value}`
      }
    }
  }
  return client
    .get(urlReservation, generateAuthorizationHeader())
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });
};

export const getAnalytics = (year=null, creator=null) => {
    let urlAnalytics = URL_ANALYTICS;

    if (year) {
        urlAnalytics += "?year=" + year
    }

    if (creator) {
        urlAnalytics += (year ? '&' : '?') + "creator=" +creator;
    }

    return client
        .get(urlAnalytics, generateAuthorizationHeader())
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const estimateReservation = (reservation_items) => {
    const params = {
        reservation_items
    };
    return client
        .post(URL_ESTIMATE_RESERVATION, params)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const createReservation = (customer_first_name, customer_last_name, customer_email, customer_phone,
    customer_nationality, payment_mode, reservation_items, date) => {
    const params = {
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_nationality,
        payment_mode,
        reservation_items,
        date
    };
    return client
        .post(URL_RESERVATION, params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


export const updateReservation = (reservation_id, customer_first_name, customer_last_name, customer_email, customer_phone,
                                  customer_nationality, payment_mode, date, cancel_reason) => {
    const params = {
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_nationality,
        payment_mode,
        date,
        cancel_reason
    };
    return client
        .patch(URL_RESERVATION + reservation_id + "/", params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export const cancelReservation = (reservation_id, cancel_reason) => {
    const params = {
        state: RESERVATION_STATES.CANCELED,
        cancel_reason
    };
    return client
        .patch(URL_RESERVATION + reservation_id + "/", params, generateAuthorizationHeader())
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
            throw err;
        });
};


